import * as React from "react"
import styled from 'styled-components'

const AboutSection = styled.section`
  background: linear-gradient(
    90deg,
    #99c1de,
    #a2c6e3,
    #accae7,
    #b5cfec,
    #bed4f0,
    #c6d9f4,
    #cfdef8,
    #d7e3fc
  );
  padding: 6rem 0 10rem;
  text-align: center;
`

const Content = styled.p`

`

const Contact = styled.p`
  text-align: center;
  margin-top: 2rem;

  a {
    color: var(--text);
  }
`


// markup
const About = () => {
  return (
    <AboutSection>
      <div className="globalWrapper">
        <h4>About Us</h4>

        <Content>At The Von Academy we are dedicated to providing a secure, inclusive and nurturing learning environment which fosters the healthy development of our young students. Instilling self-confidence is of utmost importance to us as we believe this creates a strong foundation in which children are at liberty to express their individuality. The Von Academy provides a child centered curriculum which incorporates both teacher directed and child initiated learning opportunities to meet the needs of the individual student. We are devoted to providing differentiated learning opportunities in which all children can succeed. <br /><br />The Von Academy offers an array of programs focusing on both academic, social development and overall well-being of the child. We guide our students in practicing mindfulness strategies throughout our daily routines instilling self-regulation, confidence, and self-awareness. The Von Academy knows the importance of socialization and developing social skills at this young age and provides individualized support to children as they master these new skills in a safe, nurturing environment.</Content>

        {/* <Contact>Contact: <a href="mailto:info@thevonacademy.com" target="_blank" rel="noopener noreferrer">info@thevonacademy.com</a></Contact> */}
      </div>
    </AboutSection>
  )
}

export default About
