import * as React from "react"
import styled from 'styled-components'
import crayons from '../images/pencilCrayons.jpg'

const HeroSection = styled.section`
  background-image: url(${crayons});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 50vh;

      @media all and (max-width: 767px) {
padding: 3.5rem 0;
  }
`

const BackgroundGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.2));
`

const H1 = styled.h1`
  color: var(--white);
  position: relative;
  z-index: 10;

  span {
    font-size: 4.5rem;
    font-weight: lighter;
  }

  @media all and (max-width: 767px) {
    font-size: 3.5rem;

    span {
      font-size: 3.5rem;
    }
  }
`

const H2 = styled.h2`
 color: var(--white);
  position: relative;
  z-index: 10;

`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h4 {
    color: white;
    position: relative;
    z-index: 10;
  }
`


// markup
const Hero = () => {
  return (
    <HeroSection>
      <Content className="globalWrapper">

      {/* <H1>Coming Soon</H1>
      <H1>The Von Academy plans to open our doors in 2022.</H1> */}
        <H1>Welcome <span>to</span></H1>
        <H2>The Von Academy</H2>
        <br /> <br /><br />
        {/* <h4>We will be opening our doors in 2023/2024.</h4> */}
      </Content>
      <BackgroundGradient>
      </BackgroundGradient>
    </HeroSection>
  )
}

export default Hero
