import * as React from "react"
import styled from 'styled-components'
import logo from '../images/logo-transparent.png'

const NavSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 30%;
  }

  @media all and (max-width: 767px) {
    flex-direction: column;

    img {
      width: 75%;
    }
  }
`

const Contact = styled.p`
  /* text-align: center; */
  /* margin-top: 2rem; */
  text-align: right;

  a {
    color: var(--text);
  }

    @media all and (max-width: 767px) {
  text-align: center;

  }
`

// markup
const Nav = () => {
  return (
    <div className="globalWrapper">
    <NavSection>
      <img src={logo} />

      <Contact><a href="mailto:info@thevonacademy.com" target="_blank" rel="noopener noreferrer">info@thevonacademy.com</a></Contact>
      </NavSection></div>
  )
}

export default Nav

// data
const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
]

{/* <ul style={listStyles}>
          <li style={docLinkStyle}>
            <a
              style={linkStyle}
              href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
            >
              {docLink.text}
            </a>
          </li>
          {links.map(link => (
            <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
              <span>
                <a
                  style={linkStyle}
                  href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
                >
                  {link.text}
                </a>
                {link.badge && (
                  <span style={badgeStyle} aria-label="New Badge">
                    NEW!
                  </span>
                )}
                <p style={descriptionStyle}>{link.description}</p>
              </span>
            </li>
          ))}
        </ul> */}
