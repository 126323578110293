import * as React from "react"
import { Helmet } from "react-helmet"

import '../styles/styles.scss'

import About from '../components/About'
import Hero from '../components/Hero'
import Nav from '../components/Nav'

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Von Academy</title>
      </Helmet>
      <Nav></Nav>
      <Hero></Hero>
      <About></About>
    </main>
  )
}

export default IndexPage
